export enum Brands {
  // Words
  dictionarybee = "dictionarybee",
  wordlancers = "wordlancers",
  wordzapp = "wordzapp",

  // Recipes
  bigrecipe = "bigrecipe",
  cookingprofessionally = "cookingprofessionally",
  commandcooking = "commandcooking",
  easiereats = "easiereats",
  fussykitchen = "fussykitchen",
  heartlandcooking = "heartlandcooking",
  holyrecipe = "holyrecipe",
  recipereader = "recipereader",
  recipesdirect = "recipesdirect",
  recipesaver = "recipesaver",
  lazycooking = "lazycooking",
  mamascooking = "mamascooking",

  // Jokes
  funnyeditor = "funnyeditor",
  goodcleanhumor = "goodcleanhumor",
  floridasfunny = "floridasfunny",
  masterofwit = "masterofwit",
  mydailyjoke = "mydailyjoke",
  shesofunny = "shesofunny",

  // Info
  boldfact = "boldfact",
  thisread = "thisread",
  truthfully = "truthfully",

  default = "default",
}

export default Brands;
